<template>
  <div class="grades">
    <v-data-table
      v-can="'list-academic-periods-grades'"
      :headers="headers"
      :items="gradeData"
      :no-data-text="
        $t(`No data available, click on an academic year to show it's grades`)
      "
      class="elevation-1 level1 newChild"
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="main-color">{{
            $t("Grade Settings")
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-icon
            v-if="showBtn && addAcademicVisible"
            large
            title="Add new item"
            class="mr-2 main-color"
            @click="openDialog"
            >add_circle</v-icon
          >
          <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t("Add New Grade") }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div class="form-group">
                        <label>{{ $t("Please Select Grade Level") }}</label>
                        <v-select
                          :items="grades_select"
                          item-text="name"
                          item-value="id"
                          v-model.trim="editedItem.grade_id"
                          @focus="onFocus()"
                          solo
                        >
                        </v-select>

                        <!-- <select
                        class="form-control"
                        v-model.trim="editedItem.grade_id"
                      >
                        
                        <option
                          v-for="(grade, index) in grades"
                          :key="index"
                          :value="index"
                          >{{ grade }}</option
                        >
                      </select> -->
                        <p
                          class="red--text"
                          v-if="error && validation_errors.length == 0"
                        >
                          {{ errormsg }}
                        </p>
                        <div
                          v-if="
                            validation_errors && validation_errors.length != 0
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors.grade_id"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" style="margin-top: 5%">
                      <v-switch
                        v-model="editedItem.is_active"
                        :label="$t('Admission is open')"
                        class=""
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" style="margin-top: 5%">
                      <v-switch
                        v-model="editedItem.admission_open_females"
                        :label="$t('Admission is open females')"
                        class=""
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" style="margin-top: 5%">
                      <v-switch
                        v-model="editedItem.admission_open_males"
                        :label="$t('Admission is open males')"
                        class=""
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" style="margin-top: 5%">
                      <v-switch
                        v-model="editedItem.is_accept_acceptance_age"
                        :label="$t('Allow acceptance age')"
                        class=""
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div
                        class="form-group"
                        :class="{
                          hidden: !editedItem.is_accept_acceptance_age,
                        }"
                      >
                        <label for="start_date">{{
                          $t("Admission Age From")
                        }}</label>
                        <v-text-field
                          id="addmission_age_from"
                          solo
                          autocomplete="off"
                          v-model="editedItem.addmission_age_from"
                          @keydown.prevent
                          @paste.prevent
                          @drop.prevent
                        ></v-text-field>
                        <div v-if="validation_errors">
                          <p
                            class="red--text"
                            v-for="(
                              error, index
                            ) in validation_errors.addmission_age_from"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div
                        class="form-group"
                        :class="{
                          hidden: !editedItem.is_accept_acceptance_age,
                        }"
                      >
                        <label for="start_date">{{
                          $t("Admission Age To")
                        }}</label>
                        <v-text-field
                          id="addmission_age_to"
                          solo
                          autocomplete="off"
                          v-model="editedItem.addmission_age_to"
                          @keydown.prevent
                          @paste.prevent
                          @drop.prevent
                        ></v-text-field>
                        <div v-if="validation_errors">
                          <p
                            class="red--text"
                            v-for="(
                              error, index
                            ) in validation_errors.addmission_age_to"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                    <!-- accept admission fees -->
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="editedItem.accept_admission_fees"
                        :label="$t('Accept Admission Fees')"
                        class=""
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="modal-btn-cancel cancelBtn" @click="close">{{
                  $t("Cancel")
                }}</v-btn>
                <v-btn
                  class="modal-btn-save saveBtn"
                  text
                  :disabled="disabledSubmitbtn"
                  :loading="disabledSubmitbtn"
                  @click="save"
                  >{{ $t("Save") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.grade_name="{ item }">
        <select
          :disabled="!editable || item.id != editableRowId"
          class="form-control select2"
          v-model.trim="item.grade_id"
        >
          <option value="">{{ $t("Please Select Grade Level") }}</option>
          <option v-for="(grade, index) in grades" :key="index" :value="index">
            {{ grade }}
          </option>
        </select>
      </template>

      <template #item.is_active="{ item }">
        <v-switch
          v-model="item.is_active"
          :disabled="!editable || item.id != editableRowId"
        ></v-switch>
        <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
      </template>
      <template #item.admission_open_females="{ item }">
        <v-switch
          v-model="item.admission_open_females"
          :disabled="!editable || item.id != editableRowId"
        ></v-switch>
        <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
      </template>
      <template #item.admission_open_males="{ item }">
        <v-switch
          v-model="item.admission_open_males"
          :disabled="!editable || item.id != editableRowId"
        ></v-switch>
        <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
      </template>
      <template #item.is_accept_acceptance_age="{ item }">
        <v-switch
          :disabled="!editable || item.id != editableRowId"
          v-model="item.is_accept_acceptance_age"
          @change="
            if (item.is_accept_acceptance_age == true) {
              edit(item.id);
            }
          "
        ></v-switch>
        <!-- <v-checkbox v-model="item.is_accept_acceptance_age"></v-checkbox> -->
      </template>
      <template #item.addmission_age_from="{ item }">
        <v-text-field
          :id="item.start_date_id"
          :autocomplete="off"
          solo
          v-model="item.addmission_age_from"
          v-if="
            item.is_accept_acceptance_age == true &&
            editable &&
            editableRowId == item.id
          "
          @keydown.prevent
          @paste.prevent
          @drop.prevent
        ></v-text-field>
        <span
          v-if="
            item.is_accept_acceptance_age == true &&
            (!editable || editableRowId != item.id)
          "
          >{{ item.addmission_age_from }}</span
        >
      </template>
      <template #item.addmission_age_to="{ item }">
        <v-text-field
          :id="item.end_date_id"
          :autocomplete="off"
          solo
          v-model="item.addmission_age_to"
          v-if="
            item.is_accept_acceptance_age == true &&
            editable &&
            editableRowId == item.id
          "
          @keydown.prevent
          @paste.prevent
          @drop.prevent
        ></v-text-field>
        <span
          v-if="
            item.is_accept_acceptance_age == true &&
            (!editable || editableRowId != item.id)
          "
          >{{ item.addmission_age_to }}</span
        >
        <!-- :disabled="
            !editable ||
              item.id != editableRowId ||
              item.is_accept_acceptance_age == false
          " -->
      </template>
      <!-- accept_admission_fees -->
      <template #item.accept_admission_fees="{ item }">
        <v-switch
          v-model="item.accept_admission_fees"
          :disabled="!editable || item.id != editableRowId"
        ></v-switch>
        <!-- <v-checkbox v-model="item.is_active"></v-checkbox> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-btn color="success" class="mr-4" @click="update(item)">
        Update
      </v-btn>
      <v-btn color="error" class="mr-4" @click="deleteItem(item)">
        Delete
      </v-btn> -->
        <v-icon
          v-if="editBtnVisible"
          :class="{ hidden: !editable || item.id != editableRowId }"
          :title="$t('Save')"
          small
          class="mr-2"
          @click="update(item)"
        >
          done
        </v-icon>
        <v-icon
          v-if="editBtnVisible"
          :class="{ hidden: editable }"
          small
          :title="$t('Edit')"
          class="mr-2"
          @click="edit(item.id)"
        >
          edit
        </v-icon>
        <v-icon
          :class="{ hidden: editable }"
          v-if="deleteBtnVisible"
          :title="$t('Delete')"
          small
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :multi-line="true"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->
      <div v-if="errors">
        <p
          v-for="(errors, index) in validation_errors"
          :key="index"
          class="white--text"
        >
          <span v-for="(error, index) in errors" :key="index">
            {{ error }} <br />
          </span>
        </p>
      </div>
      <span v-if="gradeError" class="white--text">
        {{ errormsg }}
      </span>
      <span v-if="updated" class="white--text"> {{ $t("Data Updated") }} </span>
      <span v-if="deleted" class="white--text"> {{ $t("Data Deleted") }} </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackTime"
      :color="snackColor"
      :multi-line="true"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ errormsg }}
      </span>

      <v-btn text @click="snackbar = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters } from "vuex";

import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
moment.locale("es");

import "../assets/jquery.calendars.package-2.1.1/css/humanity.calendars.picker.css";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.plus.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.islamic.js";

import "../assets/jquery.calendars.package-2.1.1/js/jquery.plugin.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.picker.js";
import ACL from "../acl";
export default {
  name: "AcademicPeriodGradeComponent",
  props: ["data", "academic_period_id"],
  data() {
    return {
      snackbar: false,
      editableRowId: "",
      /* snackbar data */
      grades_select: [],
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      multiline: true,
      updated: false,
      errors: false,
      deleted: false,
      gradeError: false,

      editable: false,
      headers: [
        {
          text: this.$i18n.t("Grade"),
          sortable: false,
          value: "grade_name",
        },
        { text: this.$i18n.t("Admission is open"), value: "is_active" },
        {
          text: this.$i18n.t("Admission is open females"),
          value: "admission_open_females",
        },
        {
          text: this.$i18n.t("Admission is open males"),
          value: "admission_open_males",
        },
        {
          text: this.$i18n.t("Allow acceptance age"),
          value: "is_accept_acceptance_age",
        },
        { text: this.$i18n.t("Birth date From"), value: "addmission_age_from" },
        { text: this.$i18n.t("Birth date To"), value: "addmission_age_to" },
        {
          text: this.$i18n.t("Accept Admission Fees"),
          value: "accept_admission_fees",
        },
        { text: this.$i18n.t("Action"), value: "actions" },
      ],
      grades: [],

      /* add item model */
      dialog: false,
      editedItem: {
        grade_name: "",
        grade_id: "",
        is_active: false,
        is_accept_acceptance_age: false,
        admission_open_females: false,
        admission_open_males: false,
        accept_admission_fees: false,
        addmission_age_from: "",
        addmission_age_to: "",
      },
      defaultItem: {
        grade_name: "",
        grade_id: "",
        is_active: false,
        admission_open_females: false,
        admission_open_males: false,
        is_accept_acceptance_age: false,
        addmission_age_from: "",
        addmission_age_to: "",
      },
      /* datepicker */
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      validation_errors: [],
      disabledSubmitbtn: false,
      showBtn: false,
      errormsg: "",
      error: false,
      editBtnVisible: ACL.checkPermission("edit-academic-period-grades"),
      deleteBtnVisible: ACL.checkPermission("delete-academic-period-grades"),
      addAcademicVisible: ACL.checkPermission("add-academic-period-grades"),
    };
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
    gradeData: {
      get() {
        return this.data;
      },
      set(grades) {
        return grades;
      },
    },
  },
  watch: {
    dialog(val) {
      this.validation_errors = [];
      val || this.close();
      // this.autoFocusDialog();
    },
    academic_period_id: {
      handler() {
        this.editable = false;
        this.editableRowId = "";
        if (this.academic_period_id) this.showBtn = true;
        else this.showBtn = false;
      },
    },
    gradeData() {},
  },
  methods: {
    close() {
      this.errormsg = "";
      this.dialog = false;
      this.disabledSubmitbtn = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      this.disabledSubmitbtn = true;
      this.editedItem.academic_period_id = this.academic_period_id;
      this.editedItem.grade_name = this.grades[this.editedItem.grade_id];

      // send data to serve to add new row
      axios
        .post(
          this.getApiUrl + "/school/registerAcademicPeriodGrade",
          this.editedItem,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.disabledSubmitbtn = false;
          if (response.data.status.error == true) {
            this.error = response.data.status.error;
            this.errormsg = response.data.status.message;

            if (
              Object.keys(response.data.status.validation_errors).length == 0
            ) {
              this.snackbar = true;
              this.snackText = response.data.status.message;
              this.snackColor = "red";
            } else {
              this.validation_errors = response.data.status.validation_errors;
            }
          } else {
            this.editedItem.id = response.data.data.createdId;
            this.data.push(this.editedItem);
            this.gradeData = response.data.data;
            // console.log(this.gradeData);
            this.close();
            this.editable = false;
          }
        });
    },
    update(item) {
      let id = item.id;

      this.updated = false;
      this.deleted = false;
      this.gradeError = false;
      this.errors = false;
      this.errormsg = "";

      // send data to serve to add new rowacademicGradeAllowAdmissionExam
      axios
        .post(
          this.getApiUrl + "/school/registerAcademicPeriodGrade/" + id,
          item,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == true) {
            this.validation_errors = response.data.status.validation_errors;
            this.snack = true;
            this.snackColor = "error";

            if (Object.keys(this.validation_errors).length > 0) {
              this.errors = true;
            } else {
              this.gradeError = true;
              this.errormsg = response.data.status.message;
            }
          } else {
            if (item.is_accept_acceptance_age == false) {
              item.addmission_age_from = "";
              item.addmission_age_to = "";
            }
            this.gradeData = response.data.data;
            this.snack = true;
            this.snackColor = "success";
            this.updated = true;
            this.editable = false;
            this.editableRowId = "";
          }
        });
    },
    edit(rowId) {
      this.editableRowId = rowId;
      var _this = this;
      var tableData = this.gradeData;
      // start date
      tableData.forEach(function (row, i) {
        if (rowId == row.id) {
          var id = "#" + row.start_date_id;
          $(function () {
            console.log(_this.gradeData[i].addmission_age_from + " " + i);
            $(id).calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var start =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.gradeData[i].addmission_age_from = start;
                  console.log(_this.gradeData[i].addmission_age_from + " " + i);
                } else {
                  _this.gradeData[i].addmission_age_from = "";
                }
              },
            });
          });
        }
      });
      // end date
      tableData.forEach(function (row, i) {
        if (rowId == row.id) {
          var id = "#" + row.end_date_id;
          $(function () {
            $(id).calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var start =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.gradeData[i].addmission_age_to = start;
                } else {
                  _this.gradeData[i].addmission_age_to = "";
                }
              },
            });
          });
        }
      });

      this.editable = true;
    },
    deleteItem(item) {
      if (confirm(this.$i18n.t("Are you sure you want to delete this item?"))) {
        let index = this.gradeData.indexOf(item);
        this.gradeData = [];
        let id = item.id;
        // send data to serve to add new row
        axios
          .get(this.getApiUrl + "/school/deleteAcademicPeriodGrade/" + id, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            if (response.data.status.error) {
              this.gradeError = true;
              this.errormsg = response.data.status.message;
              this.snackColor = "error";
            } else {
              this.gradeData.splice(index, 1);
              this.snackColor = "success";
              this.deleted = true;
              this.errors = false;
              this.gradeError = false;
              this.updated = false;
            }
            this.snack = true;
          });
      }
    },
    openDialog() {
      this.dialog = true;
      let _this = this;
      $(document).ready(function () {
        $("#addmission_age_from").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          defaultDate: "",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.editedItem.addmission_age_from = dateH;
            } else {
              _this.editedItem.addmission_age_from = "";
            }
          },
        });
        $("#addmission_age_to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.editedItem.addmission_age_to = dateH;
            } else {
              _this.editedItem.addmission_age_to = "";
            }
          },
        });
        // $("#endDate").calendarsPicker({
        //   maxDate: 0,
        //   onSelect: function(date) {
        //     if (date[0]) {
        //       var dateH =
        //         date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
        //       _this.level1Table.editedItem.end_date = dateH;
        //     } else {
        //       _this.level1Table.editedItem.end_date = "";
        //     }
        //   }
        // });
      });
    },
  },
  mounted() {
    // this.autoFocusModals();
    if (!this.editBtnVisible && !this.deleteBtnVisible) {
      this.headers = this.headers.filter(function (row) {
        return row.value != "action";
      });
    }
    axios.get(this.getApiUrl + "/getGrades/Array").then((response) => {
      this.grades = response.data.data.grades;
      // console.log(this.grades);
    });

    axios.get(this.getApiUrl + "/getGrades").then((response) => {
      this.grades_select = response.data.data.grades;
      // console.log(this.grades);
    });
    console.log(this.data);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_modals.scss";
.form-check input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  top: 1px;
  left: 12px;
  z-index: 1;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
}
// :disabled {
//   background-color: #cccccc;
//   cursor: not-allowed;
// }

.newChild input:disabled,
.newChild select:disabled {
  background-color: transparent !important;
  border: 0px !important;
  cursor: auto !important;
}

select,
input {
  font-size: 13px !important;
}
.layout-content-grade {
  display: inline-flex;
}
</style>
